import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Layout, Menu } from 'antd';
import { ROUTES } from '../../config';
import { UserContext } from '../../App';
import { UserOutlined, LogoutOutlined, RightOutlined, LeftOutlined, SearchOutlined } from '@ant-design/icons';
import Routes from '../routes/Routes';
import { Content, Header } from 'antd/lib/layout/layout';
import TopBarMenu from './TopBarMenu';

const { SubMenu } = Menu;
const { Sider } = Layout;

const NavMenu = (props) => {
  const { isCollapsed, collapsedNav } = props;
  const [collapsed, setCollapsed] = useState(true);

  const currentuser = useContext(UserContext);

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('amounts');
    sessionStorage.removeItem('orderedProducts');
    sessionStorage.removeItem('totalPrice');
    window.location.href = '/company';
  };

  const menuItems =
    currentuser.data &&
    currentuser.data.role &&
    ROUTES.filter((route) => route.showInMenu.some((item) => currentuser.data.role.includes(item))).map((route) => {
      return {
        label: <Link to={route.children[0].path}>{route.label}</Link>,
        key: route.label,
        icon: (
          <Link to={route.children[0].path}>
            <i className={`${route.icon}`}></i>
          </Link>
        ),
      };
    });

  return (
    <Layout
      style={{
        minHeight: '100vh',
        display: 'flex',
      }}
    >
      <Sider
        breakpoint='lg'
        className={collapsedNav ? 'nav-menu-collapsed' : 'nav-display'}
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ zIndex: 6, overflowX: 'hidden' }}
      >
        <Link to='/company'>
          <div className='logo'>
            <img src='/himtex-logo.svg' style={{ maxHeight: '49px', width: '70%' }} alt='Universal api' />
          </div>
        </Link>

        <Button
          className='openSideBar'
          type='text'
          icon={collapsed ? <RightOutlined style={{ color: 'white' }} /> : <LeftOutlined style={{ color: 'white' }} />}
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: '16px',
            width: 64,
            height: 64,
          }}
        />

        <Menu theme='dark' mode='inline' items={menuItems} defaultSelectedKeys={[window.location.pathname]}>
          {/* {currentuser.data &&
            currentuser.data.role &&
            ROUTES.filter((route) => route.showInMenu.some((item) => currentuser.data.role.includes(item))).map(
              (route) => (
                <SubMenu
                  className='submenu'
                  key={route.label.toLowerCase()}
                  title={
                    isCollapsed ? (
                      <route.icon />
                    ) : (
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <i className={`navMenuIcon ${route.icon}`}></i>
                        <Link to={route.children[0].path}>{route.label}</Link>
                      </span>
                    )
                  }
                >
                </SubMenu>
              ),
            )} */}
        </Menu>
        <div className='sider-profile-controls'>
          {/* <Link
            to={`/admin/view-user/${currentuser && currentuser?.data?.id}`}
            className='sider-profile-controls-profile'
          >
            <UserOutlined />
          </Link> */}

          <LogoutOutlined onClick={handleSignOutSubmit} className='sider-profile-controls-logout' />
        </div>
      </Sider>
      <Layout>
        <TopBarMenu className='newTopBar' topBar={props.topBar} setTopBar={props.setTopBar} />
        <Content
          style={{
            margin: '0 16px',
          }}
          className='content-space'
        >
          <Routes />
        </Content>
      </Layout>
    </Layout>
  );
};
export default NavMenu;
