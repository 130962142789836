import Data from '../pages/data/Data';
import Users from '../pages/users/Users';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
import ViewBranches from '../pages/users/ViewBranches';
import AddCompanyBranch from '../pages/users/AddCompanyBranch';
import ReclamationsTabs from '../pages/reclamations/ReclamationsTabs';
import MyOrders from '../pages/myOrders/MyOrders';
import Catalogs from '../pages/catalogs/Catalogs';
import ViewOneOrder from '../pages/orders/ViewOneOrder';

const routes = [
  {
    label: 'Korisnici',
    showInMenu: ['none'],
    children: [
      {
        label: 'Svi korisnici',
        path: '/company/users',
        component: Users,
        allowed: ['admin', 'companyAdmin', 'wholesale user', 'commercialist'],
        showInMenu: false,
      },
      {
        label: 'Novi korisnik',
        path: '/company/new-user',
        component: EditUser,
        allowed: ['admin', 'user', 'commercialist'],
        showInMenu: false,
      },
      {
        label: 'Izmena korisnika',
        path: '/company/edit-user/:userId',
        component: EditUser,
        allowed: ['admin', 'user', 'commercialist'],
        showInMenu: false,
      },
      {
        label: 'Pregled korisnika',
        path: '/company/view-user/:id',
        component: ViewUser,
        allowed: ['admin', 'companyAdmin', 'company', 'wholesale user', 'commercialist'],
        showInMenu: false,
      },
      {
        label: 'Svi ogranci',
        path: '/company/branches',
        component: ViewBranches,
        allowed: ['companyAdmin', 'commercialist'],
        showInMenu: false,
      },
      {
        label: 'Novi ogranak',
        path: '/company/new-branch',
        component: AddCompanyBranch,
        allowed: ['companyAdmin', 'commercialist'],
        showInMenu: false,
      },
      {
        label: 'Izmeni ogranak',
        path: '/company/edit-branch/:id',
        component: AddCompanyBranch,
        allowed: ['companyAdmin', 'commercialist'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'SVI PROIZVODI',
    icon: 'ri-search-line',
    showInMenu: ['admin', 'companyAdmin', 'wholesale user', 'commercialist'],
    children: [
      {
        label: 'Svi proizvodi',
        value: 'SVI PROIZVODI',
        path: '/company/svi-proizvodi',
        component: Data,
        allowed: ['admin', 'companyAdmin', 'company', 'wholesale user', 'commercialist'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'HOGERT ALATI',
    icon: 'ri-tools-line',
    showInMenu: ['admin', 'companyAdmin', 'wholesale user', 'commercialist'],
    children: [
      {
        label: 'Svi proizvodi',
        value: 'HOGERT ALATI',
        path: '/company/hogert',
        component: Data,
        allowed: ['admin', 'companyAdmin', 'company', 'wholesale user', 'commercialist'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'HOGERT HTZ',
    icon: 'ri-user-line',
    showInMenu: ['admin', 'companyAdmin', 'company', 'wholesale user', 'commercialist'],
    children: [
      {
        label: 'Svi proizvodi',
        value: 'HOGERT HTZ',
        path: '/company/hogert-htz',
        component: Data,
        allowed: ['admin', 'companyAdmin', 'company', 'wholesale user', 'commercialist'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'GTV LED',
    icon: 'ri-lightbulb-line',
    showInMenu: ['admin', 'companyAdmin', 'company', 'wholesale user', 'commercialist'],
    children: [
      {
        label: 'Svi proizvodi',
        value: 'GTV LED',
        path: '/company/gtv-led',
        component: Data,
        allowed: ['admin', 'companyAdmin', 'company', 'wholesale user', 'commercialist'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'GTV OKOV',
    icon: 'ri-door-line',
    showInMenu: ['admin', 'companyAdmin', 'company', 'wholesale user', 'commercialist'],
    children: [
      {
        label: 'Svi proizvodi',
        value: 'GTV OKOV',
        path: '/company/gtv-okov',
        component: Data,
        allowed: ['admin', 'companyAdmin', 'company', 'wholesale user', 'commercialist'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'MOLERSKI ALAT',
    icon: 'ri-contrast-drop-2-line',
    showInMenu: ['admin', 'companyAdmin', 'company', 'wholesale user', 'commercialist'],
    children: [
      {
        label: 'Svi proizvodi',
        value: 'MOLERSKI ALAT',
        path: '/company/molerski-alat',
        component: Data,
        allowed: ['admin', 'companyAdmin', 'company', 'wholesale user', 'commercialist'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'JUGOHEMIKA',
    icon: 'ri-contrast-drop-2-line',
    showInMenu: ['admin', 'companyAdmin', 'company', 'wholesale user', 'commercialist'],
    children: [
      {
        label: 'Svi proizvodi',
        value: 'JUGOHEMIKA',
        path: '/company/dandd-jugohemika',
        component: Data,
        allowed: ['admin', 'companyAdmin', 'company', 'wholesale user', 'commercialist'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'MIMONT',
    icon: 'ri-paint-brush-line',
    showInMenu: ['admin', 'companyAdmin', 'company', 'wholesale user', 'commercialist'],
    children: [
      {
        label: 'Svi proizvodi',
        value: 'MIMONT',
        path: '/company/mimont',
        component: Data,
        allowed: ['admin', 'companyAdmin', 'company', 'wholesale user', 'commercialist'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'IRKOM',
    icon: 'ri-contrast-drop-2-line',
    showInMenu: ['admin', 'companyAdmin', 'company', 'wholesale user', 'commercialist'],
    children: [
      {
        label: 'Svi proizvodi',
        value: 'IRKOM',
        path: '/company/irkom',
        component: Data,
        allowed: ['admin', 'companyAdmin', 'company', 'wholesale user', 'commercialist'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'MAXIMA',
    icon: 'ri-contrast-drop-2-line',
    showInMenu: ['admin', 'companyAdmin', 'company', 'wholesale user', 'commercialist'],
    children: [
      {
        label: 'Svi proizvodi',
        value: 'MAXIMA',
        path: '/company/maxima',
        component: Data,
        allowed: ['admin', 'companyAdmin', 'company', 'wholesale user', 'commercialist'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Reclamations',
    icon: 'ri-list-check',
    showInMenu: ['none'],
    children: [
      {
        label: 'Reklamacije',
        value: 'Reklamacije',
        path: '/company/reclamations/:id',
        component: ReclamationsTabs,
        allowed: ['admin', 'companyAdmin', 'wholesale user', 'commercialist'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'ViewOneOrder',
    icon: 'ri-list-check',
    showInMenu: ['none'],
    children: [
      {
        label: 'Pojedinacna narudzbina',
        value: 'Pojedinacna narudzbina',
        path: '/company/view-a-order/:id',
        component: ViewOneOrder,
        allowed: ['admin', 'companyAdmin', 'wholesale user', 'commercialist'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Orders',
    icon: 'ri-list-check',
    showInMenu: ['none'],
    children: [
      {
        label: 'Moje narudzbine',
        value: 'Moje narudzbine',
        path: '/company/orders/:id',
        component: MyOrders,
        allowed: ['admin', 'companyAdmin', 'wholesale user', 'commercialist'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Catalogs',
    icon: 'ri-list-check',
    showInMenu: ['none'],
    children: [
      {
        label: 'Katalozi i ceonvnici',
        value: 'Katalozi i ceonvnici',
        path: '/company/catalogs/:id',
        component: Catalogs,
        allowed: ['admin', 'companyAdmin', 'wholesale user', 'commercialist'],
        showInMenu: false,
      },
    ],
  },
];

export default routes;
