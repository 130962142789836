import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavMenu from './NavMenu';
import Routes from '../routes/Routes';
import { Layout, Button } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import TopBarMenu from './TopBarMenu';
import TopSearchBar from './TopSearchBar';
import { Footer } from '../footer';
import NavBarTablet from './NavBarTablet';

const { Content } = Layout;

const AdminLayout = (props) => {
  // const user = useContext(UserContext);
  const [isCollapsed, setCollapsed] = useState(false);
  const [collapsedNav, setCollapsedNav] = useState(false);

  // const handleSignOutSubmit = async (event) => {
  //   event.preventDefault();
  //   sessionStorage.removeItem('user');
  //   window.location.href = '/login';
  // };

  // const userMenu = (
  //   <Menu style={{ width: 120 }}>
  //     <Menu.Item>
  //       <Link to={`/company/edit-user/${user.data ? user.data.id : ''}`}>My account</Link>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <span onClick={handleSignOutSubmit}>Log out</span>
  //     </Menu.Item>
  //   </Menu>
  // );

  return (
    <>
      <Layout className='oldLayout'>
        <NavMenu isCollapsed={isCollapsed} setCollapsed={setCollapsed} collapsedNav={collapsedNav} />
        {/* {props.topBar !== 'MOJE PORUDŽBINE' && <TopSearchBar />} */}
        <Layout style={{ display: 'flex', flexDirection: 'column' }}>
          <TopBarMenu topBar={props.topBar} setTopBar={props.setTopBar} />
          <div className='layout-container'>
            <Button type='primary' className='btn-collapse' onClick={() => setCollapsedNav(!collapsedNav)}>
              {React.createElement(collapsedNav ? MenuUnfoldOutlined : MenuFoldOutlined)}
            </Button>
            <Link to='/company' className='logo-mobile'>
              <div className='logo'>
                <img src='himtex-logo.svg' alt='Universal logo' />
              </div>
            </Link>
          </div>

          <Content className='content-space' style={{ minHeight: '900px' }}>
            <Routes />
          </Content>
        </Layout>
      </Layout>

      {/* CLIENT TABLET */}
      <Layout className='newLayout'>
        <NavBarTablet isCollapsed={isCollapsed} setCollapsed={setCollapsed} collapsedNav={collapsedNav} topBar={props.topBar} setTopBar={props.setTopBar} />
      </Layout>
      <Footer />
    </>
  );
};

export default AdminLayout;
